import { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import InvoiceManagement from "./pages/InvoiceManagement";
import OurProcess from "./pages/OurProcess";
import StudyEcomm from "./pages/StudyEcomm";
import StudyIntranet from "./pages/StudyIntranet";
type Props = {};

type State = {};

export default class App extends Component<Props, State> {
	componentDidMount() {}

	render() {
		return (
			<>
				<Routes>
					<Route path="/home" element={<Home />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/our-process" element={<OurProcess />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/invoice-management" element={<InvoiceManagement />} />
					<Route path="/study-ecomm" element={<StudyEcomm />} />
					<Route path="/study-intranet" element={<StudyIntranet />} />
					<Route path="*" element={<Navigate to="/home" />} />
				</Routes>
			</>
		);
	}
}
