import React from "react";
import HomeAbout from "../components/HomeAbout.component";
import HomeHero from "../components/HomeHero.component";
import HomeOurProducts from "../components/HomeOurProducts.component";
import HomeServices from "../components/HomeServices.component";
import AppLayout from "../layouts/AppLayout";

export default class Home extends React.Component {
	render() {
		return (
			<AppLayout>
				<HomeHero />
				<HomeAbout />
				<HomeServices />
				<HomeOurProducts />
			</AppLayout>
		);
	}
}
