import React from "react";
import Footer from "./Footer.component";
import Header from "./Header.component";

export default class AppLayout extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<>
				<Header></Header>
				<div className="pt-20">{this.props.children}</div>
				<Footer></Footer>
			</>
		);
	}
}
