import React from "react";
import AppLayout from "../layouts/AppLayout";
import mddr from "../assets/mddr.png";

export default class OurProcess extends React.Component<any, any> {
	render() {
		return (
			<AppLayout>
				<section className="bg-white pb-6">
					<div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
						<div className="container mx-auto px-6 p-6 bg-white">
							<div className="mb-16 text-center">
								<h4 className="text-base text-indigo-600 font-semibold tracking-wide uppercase hidden">Features</h4>
								<p className="mt-2 text-5xl lg:text-7xl font-bold tracking-tight text-gray-900">How we change the game</p>
							</div>

							<div className="flex flex-wrap my-12">
								<div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 p-8">
									<div className="flex items-center mb-6">
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-20 w-20 text-newdawn-950 mb-auto">
											<path d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"></path>
										</svg>
										<div className="ml-4 text-xl mb-auto">Client Consultation and Needs Analysis</div>
									</div>
									<p className="leading-loose text-gray-500">Engaging with clients to understand their specific needs and challenges. This initial stage involves detailed discussions to assess requirements and define project scope.</p>
								</div>

								<div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r p-8">
									<div className="flex items-center mb-6">
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-20 w-20 text-newdawn-950 mb-auto">
											<path d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"></path>
										</svg>
										<div className="ml-4 text-xl mb-auto">Solution Design and Planning</div>
									</div>
									<p className="leading-loose text-gray-500">Developing a tailored solution based on the client's requirements. This process includes creating a strategic plan outlining the project timeline, resources needed, and technical specifications.</p>
								</div>

								<div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 p-8">
									<div className="flex items-center mb-6">
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-20 w-20 text-newdawn-950 mb-auto">
											<path d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"></path>
										</svg>
										<div className="ml-4 text-xl mb-auto">Agile Development and Implementation:</div>
									</div>
									<p className="leading-loose text-gray-500">Employing agile methodologies for software development, allowing for flexibility, regular client feedback, and iterative progress. This ensures solutions are developed efficiently and align closely with client expectations.</p>
								</div>

								<div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0 p-8">
									<div className="flex items-center mb-6">
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-20 w-20 text-newdawn-950 mb-auto">
											<path d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"></path>
										</svg>
										<div className="ml-4 text-xl mb-auto">Quality Assurance and Testing</div>
									</div>
									<p className="leading-loose text-gray-500">Rigorous testing of solutions to ensure they meet quality standards and are free from bugs. This includes multiple stages of testing, such as unit testing, integration testing, and user acceptance testing.</p>
								</div>

								<div className="w-full border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0 p-8">
									<div className="flex items-center mb-6">
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-20 w-20 text-newdawn-950 mb-auto">
											<path d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"></path>
										</svg>
										<div className="ml-4 text-xl mb-auto">Deployment and Integration:</div>
									</div>
									<p className="leading-loose text-gray-500">Rolling out the solution in a controlled manner, ensuring seamless integration with existing client systems and minimal disruption to business operations.</p>
								</div>

								<div className="w-full md:w-1/2 lg:w-1/3 p-8">
									<div className="flex items-center mb-6">
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-20 w-20 text-newdawn-950 mb-auto">
											<path d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"></path>
										</svg>
										<div className="ml-4 text-xl mb-auto">Ongoing Support and Maintenance</div>
									</div>
									<p className="leading-loose text-gray-500">Providing continuous support and maintenance post-deployment to ensure the solution remains effective and up-to-date. This may include regular updates, troubleshooting, and enhancements based on evolving client needs.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<img src={mddr} alt="" className="w-full" />
				<div className="bg-gray-50 flex justify-center w-full mx-auto">
					<div className="max-w-7xl mx-auto grid grid-cols-4 gap-8 p-8 md:p-16">
						<div className="col-span-2 relative px-5 pt-10 pb-2 flex flex-col justify-start items-center border-2 border-gray-300 rounded-xl text-gray-900">
							<span className="absolute -top-6 p-3 border-2 border-gray-300 rounded-full bg-gray-800 text-white">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="w-7 h-7 text-yellow-640">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
								</svg>
							</span>
							<h2 className="my-1 gradient-red text-lg uppercase tracking-wide">Meet</h2>
							<p className="py-2 text-center text-base">This initial stage is all about building a strong foundation for the project. It involves meeting with clients to understand their needs, challenges, and objectives. During these meetings, we engage in in-depth discussions to clearly define the project scope, establish goals, and set expectations. This collaborative approach ensures we are perfectly aligned with our clients' vision right from the start.</p>
						</div>

						<div className="col-span-2 relative px-5 pt-10 pb-2 flex flex-col justify-start items-center border-2 border-gray-300 rounded-xl text-gray-900">
							<span className="absolute -top-6 p-3 border-2 border-gray-300 rounded-full bg-gray-800 text-white">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="w-7 h-7 text-yellow-640">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path>
								</svg>
							</span>
							<h2 className="my-1 gradient-red text-lg uppercase tracking-wide">Develop</h2>
							<p className="py-2 text-center text-base">In the development phase, our team of experts gets to work on creating a solution tailored to the specific requirements identified during the meeting phase. Utilizing the latest technologies and methodologies, we focus on building a robust, scalable, and efficient product. This stage is characterized by agile development practices, allowing for flexibility, iterative progress, and regular client feedback to ensure the solution evolves in line with their expectations.</p>
						</div>

						<div className="col-span-2 relative px-5 pt-10 pb-2 flex flex-col justify-start items-center border-2 border-gray-300 rounded-xl text-gray-900">
							<span className="absolute -top-6 p-3 border-2 border-gray-300 rounded-full bg-gray-800 text-white">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="w-7 h-7 text-yellow-640">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
								</svg>
							</span>
							<h2 className="my-1 gradient-red text-lg uppercase tracking-wide">Deploy</h2>
							<p className="py-2 text-center text-base">Once the development is complete, the next step is deployment. This involves the careful integration of the new solution into the client's existing systems and processes. We ensure a smooth transition with minimal disruption to daily operations. Our deployment process is thorough and considers all aspects of the client's infrastructure to ensure optimal performance and compatibility.</p>
						</div>

						<div className="col-span-2 relative px-5 pt-10 pb-2 flex flex-col justify-start items-center border-2 border-gray-300 rounded-xl text-gray-900">
							<span className="absolute -top-6 p-3 border-2 border-gray-300 rounded-full bg-gray-800 text-white">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="w-7 h-7 text-yellow-640">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
								</svg>
							</span>
							<h2 className="my-1 gradient-red text-lg uppercase tracking-wide">Repeat</h2>
							<p className="py-2 text-center text-base">This final stage underscores our commitment to continuous improvement and long-term partnerships. After deployment, we revisit and review the project's outcomes, gather feedback, and identify areas for future enhancements. This iterative process ensures that the solutions we provide remain effective, relevant, and aligned with our clients' evolving needs. It's an ongoing cycle of refinement and advancement, aiming to foster growth and innovation.</p>
						</div>
					</div>
				</div>
			</AppLayout>
		);
	}
}
