import React from "react";
import AppLayout from "../layouts/AppLayout";
import purchases from "../assets/inv_purchases.png";
import shops from "../assets/inv_shops.png";
import sales from "../assets/inv_sales.png";
import dashboard from "../assets/inv_dashboard.png";

export default class InvoiceManagement extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			selected: 1,
		};
	}
	getFeatureClass(tab: number) {
		if (this.state.selected === tab) {
			return " lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10";
		}

		return "hover:bg-white/10 lg:hover:bg-white/5";
	}
	getImage() {
		if (this.state.selected === 1) {
			return dashboard;
		} else if (this.state.selected === 2) {
			return shops;
		} else if (this.state.selected === 3) {
			return sales;
		}
		return purchases;
	}
	render() {
		return (
			<AppLayout>
				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-20 text-center lg:pt-32">
					<h1 className="mx-auto max-w-4xl font-display text-4xl font-medium tracking-tight text-slate-900 lg:text-7xl">
						Simplifying accounting for
						<span className="relative whitespace-nowrap text-alpine-600">
							<svg aria-hidden="true" viewBox="0 0 418 42" className="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none">
								<path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
							</svg>
							<span className="relative">&nbsp;small businesses.</span>
						</span>
					</h1>
					<p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">Our bookkeeping software prioritizes ease of use over complex accuracy, with a focus on user-friendliness and hoping for smooth financial reviews.</p>
					<div className="mt-10 flex justify-center gap-x-6">
						<a href="/contact-us" className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900" color="slate">
							Get 15 days free trial
						</a>
					</div>
				</div>
				<section className="relative overflow-hidden gradient1 pb-28 pt-20 sm:py-32">
					<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
						<div className="max-w-4xl md:mx-auto md:text-center xl:max-w-none">
							<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">All the essentials for managing your book keeping efficiently.</h2>
							<p className="mt-6 text-lg tracking-tight text-blue-100">Comprehensive Bookkeeping Tools: Simplifying Your Financial Management with Everything Required to Efficiently Run Your Books</p>
						</div>
						<div className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">
							<div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
								<div className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal" role="tablist" aria-orientation="vertical">
									<div
										className={"group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 " + this.getFeatureClass(1)}
										onClick={() => {
											this.setState({
												selected: 1,
											});
										}}
									>
										<h3>
											<button className="font-display text-lg ui-not-focus-visible:outline-none text-blue-100 lg:text-white" role="tab" type="button" aria-selected="true" data-headlessui-state="selected" aria-controls="headlessui-tabs-panel-:R15aalla:">
												<span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>Reporting
											</button>
										</h3>
										<p className="mt-2 hidden text-sm lg:block text-white">Our Reporting Module offers customizable, real-time insights into your business's financial and operational performance, enabling tailored reports on sales, expenses, and inventory for informed decision-making.</p>
									</div>
									<div
										className={"group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 " + this.getFeatureClass(2)}
										onClick={() => {
											this.setState({
												selected: 2,
											});
										}}
									>
										<h3>
											<button className="font-display text-lg ui-not-focus-visible:outline-none text-blue-100 hover:text-white lg:text-white" role="tab" type="button" aria-selected="false" data-headlessui-state="" aria-controls="headlessui-tabs-panel-:Rlaalla:">
												<span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>Manage multiple shops
											</button>
										</h3>
										<p className="mt-2 hidden text-sm lg:block text-blue-100 group-hover:text-white">Our Invoice Management system facilitates handling multiple stores, offering a consolidated view of sales and stock across all locations, enabling efficient management and oversight from a single dashboard.</p>
									</div>
									<div
										className={"group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 " + this.getFeatureClass(3)}
										onClick={() => {
											this.setState({
												selected: 3,
											});
										}}
									>
										<h3>
											<button className="font-display text-lg ui-not-focus-visible:outline-none text-blue-100 hover:text-white lg:text-white" role="tab" type="button" aria-selected="false" data-headlessui-state="" aria-controls="headlessui-tabs-panel-:Rtaalla:">
												<span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>Sales Module
											</button>
										</h3>
										<p className="mt-2 hidden text-sm lg:block text-blue-100 group-hover:text-white">Our Sales Management Module enhances your business's sales process, offering tools for order processing, customer tracking, and performance analytics, all integrated into a user-friendly interface for streamlined sales operations and improved customer engagement.</p>
									</div>
									<div
										className={"group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 " + this.getFeatureClass(4)}
										onClick={() => {
											this.setState({
												selected: 4,
											});
										}}
									>
										<h3>
											<button className="font-display text-lg ui-not-focus-visible:outline-none text-blue-100 hover:text-white lg:text-white" role="tab" type="button" aria-selected="false" data-headlessui-state="" aria-controls="headlessui-tabs-panel-:Rdaalla:">
												<span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>Purchases
											</button>
										</h3>
										<p className="mt-2 hidden text-sm lg:block text-blue-100 group-hover:text-white">Our Invoice Management system streamlines your business's purchasing process, allowing for efficient management of purchase orders, vendor payments, and transaction records. It simplifies procurement from order to payment, ensures accurate documentation, and offers real-time spending insights for better financial control and strategic decision-making.</p>
									</div>
								</div>
							</div>
							<div className="">
								{this.state.selected === 1 && <p className="block lg:hidden text-white mt-2">Our Reporting Module offers customizable, real-time insights into your business's financial and operational performance, enabling tailored reports on sales, expenses, and inventory for informed decision-making.</p>}
								{this.state.selected === 2 && <p className="block lg:hidden text-white mt-2">Our Invoice Management system facilitates handling multiple stores, offering a consolidated view of sales and stock across all locations, enabling efficient management and oversight from a single dashboard.</p>}
								{this.state.selected === 3 && <p className="block lg:hidden text-white mt-2">Our Sales Management Module enhances your business's sales process, offering tools for order processing, customer tracking, and performance analytics, all integrated into a user-friendly interface for streamlined sales operations and improved customer engagement.</p>}
								{this.state.selected === 4 && <p className="block lg:hidden text-white mt-2">Our Invoice Management system streamlines your business's purchasing process, allowing for efficient management of purchase orders, vendor payments, and transaction records. It simplifies procurement from order to payment, ensures accurate documentation, and offers real-time spending insights for better financial control and strategic decision-making.</p>}
								<div>
									<div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
										<img alt="purchases" decoding="async" data-nimg="1" className="" src={this.getImage()} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32">
					<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="mx-auto max-w-2xl md:text-center">
							<h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">Streamline your daily business operations.</h2>
							<p className="mt-4 text-lg tracking-tight text-slate-700">Because suggesting the complication of your daily business tasks would, understandably, be quite perplexing.</p>
						</div>
						<div className="-mx-4 mt-20 lg:flex gap-x-10 overflow-hidden px-4 sm:-mx-6 sm:px-6">
							<div>
								<div className="mx-auto max-w-2xl">
									<div className="w-9 rounded-lg bg-newdawn-950">
										<svg aria-hidden="true" className="h-9 w-9" fill="none">
											<defs>
												<linearGradient id=":R2mella:" x1="11.5" y1="18" x2="36" y2="15.5" gradientUnits="userSpaceOnUse">
													<stop offset=".194" stopColor="#fff"></stop>
													<stop offset="1" stopColor="#6692F1"></stop>
												</linearGradient>
											</defs>
											<path d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5" stroke="url(#:R2mella:)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										</svg>
									</div>
									<h3 className="mt-6 text-sm font-medium text-newdawn-950">Reporting</h3>
									<p className="mt-2 font-display text-xl text-slate-900">Keep abreast of your business affairs with our constantly updated reporting features.</p>
									<p className="mt-4 text-sm text-slate-600">Effortless Monitoring: Real-Time Reporting to Keep Your Business Ahead. Our advanced reporting features ensure you're always informed with the latest data, providing a clear overview of your business's performance. Stay ahead of the curve with up-to-date insights, enabling proactive decision-making and strategic planning.</p>
								</div>
							</div>
							<div className="mt-5 lg:mt-0">
								<div className="mx-auto max-w-2xl">
									<div className="w-9 rounded-lg bg-newdawn-950">
										<svg aria-hidden="true" className="h-9 w-9" fill="none">
											<path opacity=".5" d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff"></path>
											<path opacity=".3" d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff"></path>
											<path d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff"></path>
										</svg>
									</div>
									<h3 className="mt-6 text-sm font-medium text-newdawn-950">Inventory Management</h3>
									<p className="mt-2 font-display text-xl text-slate-900">Maintain precise control over your inventory levels with reliable and accurate tracking.</p>
									<p className="mt-4 text-sm text-slate-600">With our precise inventory tracking system, you'll always have a clear picture of your stock levels. This ensures you never lose sight of what's available, preventing stockouts and overstocking, and maintaining the perfect balance for efficient operations."</p>
								</div>
							</div>
							<div className="mt-5 lg:mt-0">
								<div className="mx-auto max-w-2xl">
									<div className="w-9 rounded-lg bg-newdawn-950">
										<svg aria-hidden="true" className="h-9 w-9" fill="none">
											<path opacity=".5" d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z" fill="#fff"></path>
											<path d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z" fill="#fff"></path>
										</svg>
									</div>
									<h3 className="mt-6 text-sm font-medium text-newdawn-950">Expenses</h3>
									<p className="mt-2 font-display text-xl text-slate-900">Efficiently manage and track all business expenses with our comprehensive Expense Module.</p>
									<p className="mt-4 text-sm text-slate-600">Our Expense Module provides an in-depth and user-friendly platform for managing every aspect of your business expenditures. From logging individual transactions to analyzing overall expense trends, this module simplifies financial oversight and enhances budgetary control, ensuring fiscal health and efficiency.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="bg-newdawn-950 py-20 sm:py-32">
					<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="md:text-center">
							<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
								<span className="relative whitespace-nowrap">
									<svg aria-hidden="true" viewBox="0 0 281 40" preserveAspectRatio="none" className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-400">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
										></path>
									</svg>
									<span className="relative">Simple pricing,</span>
								</span>{" "}
								for everyone.
							</h2>
							<p className="mt-4 text-lg text-slate-400">Affordable Rates, Tailored to Suit All Business Sizes</p>
						</div>
						<div className="-mx-4 mt-16 max-w-2xl sm:mx-auto lg:-mx-8 lg:max-w-none xl:mx-0 flex">
							<section className="flex flex-col rounded-3xl px-6 sm:px-8 order-first bg-alpine-600 py-8 lg:order-none m-auto">
								<h3 className="mt-5 font-display text-lg text-white hidden">Business</h3>
								<p className="mt-2 text-base text-white">Perfect for small to medium sized businesses.</p>
								<p className="order-first font-display text-5xl font-light tracking-tight text-white">$20 per month</p>
								<ul className="order-last mt-10 flex flex-col gap-y-3 text-sm text-white">
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Manage shops</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Manage store</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Unlimited Purchases</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Manage categories and items</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Manage stock and transactions</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Expense tracking</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Sales and Reports</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Tax Reporting</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Access from any device</span>
									</li>
									<li className="flex">
										<svg aria-hidden="true" className="h-6 w-6 flex-none fill-current stroke-current text-white">
											<path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth="0"></path>
											<circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
										</svg>
										<span className="ml-4">Data protection</span>
									</li>
								</ul>
								<a className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-8" color="white" aria-label="Get started with the Small business plan for $15" href="/contact-us">
									Get started
								</a>
							</section>
						</div>
					</div>
				</section>
				<section className="relative overflow-hidden gradient1 py-32">
					<div className="mx-auto max-w-lg text-center">
						<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">Get started today</h2>
						<p className="mt-4 text-lg tracking-tight text-white">It’s time to take control of your books. Buy our software so you can feel like you’re doing something productive.</p>
						<a className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-10" color="white" href="/contact-us">
							Get 15 days free trial
						</a>
					</div>
				</section>
			</AppLayout>
		);
	}
}
