import React from "react";
import AppLayout from "../layouts/AppLayout";
import amal from "../assets/head/amal.jpg";
import yaseen from "../assets/head/yaseen.png";
import fousiya from "../assets/head/fousiya.jpg";

import ameen from "../assets/head/ameen.jpeg";
import amina from "../assets/head/amina.jpeg";
import aneeta from "../assets/head/aneeta.jpeg";
import ashik from "../assets/head/ashik.jpeg";
import favas from "../assets/head/favas.jpeg";
import gokul from "../assets/head/gokul.jpeg";
import karthik from "../assets/head/karthik.jpeg";
import naveen from "../assets/head/naveen.jpeg";
import noordeen from "../assets/head/noordeen.jpeg";
import rashid from "../assets/head/rashid.jpeg";
import roshna from "../assets/head/roshna.jpeg";
import sajad from "../assets/head/sajad.jpeg";
import thasni from "../assets/head/thasni.jpeg";
import yadhu from "../assets/head/yadhu.jpeg";
import sanjay from "../assets/head/sanjay.jpg";
import pranav from "../assets/head/pranav.jpg";

export default class AboutUs extends React.Component<any, any> {
	render() {
		return (
			<AppLayout>
				<div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
					<div className="flex flex-col lg:flex-row justify-between gap-8">
						<div className="w-full lg:w-5/12 flex flex-col mb-auto mt-20">
							<h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">About Us</h1>
							<p className="font-normal text-base leading-6 text-gray-600">At New Dawn IT Solutions, we are committed to revolutionizing the way businesses engage with technology. Founded with a vision to bridge the gap between technical possibilities and practical applications, we have grown into a beacon of innovation in the IT industry. Our team, a blend of seasoned experts and dynamic innovators, is dedicated to delivering customized, cutting-edge solutions that drive efficiency, growth, and success.</p>
							<p className="font-normal text-base leading-6 text-gray-600"></p>
						</div>
						<div className="w-full lg:w-8/12">
							<img className="w-full h-full" src="https://i.ibb.co/FhgPJt8/Rectangle-116.png" alt="A group of People" />
						</div>
					</div>

					<div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
						<div className="w-full lg:w-5/12 flex flex-col mb-auto mt-20">
							<h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">Our Story</h1>
							<p className="font-normal text-base leading-6 text-gray-600">Our journey began with a simple yet powerful idea: to create IT solutions that are not just functional, but transformative. Over the years, we've expanded our expertise across various domains, including custom software development, cloud computing, and cybersecurity, always staying ahead of the technological curve. Our passion for excellence and commitment to customer satisfaction has helped us forge lasting relationships with clients across different sectors.</p>
						</div>
						<div className="w-full lg:w-8/12 lg:pt-8 hidden">
							<div className="grid md:grid-cols-5 sm:grid-cols-2 grid-cols-1 lg:gap-1 shadow-lg rounded-md">
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={amal} alt="Amal" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={yaseen} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={fousiya} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={roshna} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={naveen} alt="Naveen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={gokul} alt="Naveen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={amina} alt="Amina" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={favas} alt="Favas" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={karthik} alt="Karthik" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={sanjay} alt="Karthik" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={aneeta} alt="Karthik" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={ashik} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={sajad} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={thasni} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={rashid} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={noordeen} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={yadhu} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={ameen} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
								<div className="p-4 pb-6 flex justify-center flex-col items-center">
									<img src={pranav} alt="Yaseen" className="rounded-full w-28 h-28 border border-alpine-600" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AppLayout>
		);
	}
}
