import React from "react";
import inv from "../assets/inv-art.png";
import trad from "../assets/trad-art.png";
import order from "../assets/order.png";
import event from "../assets/event.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cube";
import { Autoplay } from "swiper/modules";

export default class HomeOurProducts extends React.Component {
	render() {
		return (
			<>
				<section className="py-20 bg-alpine-50">
					<div className="mx-auto">
						<div className="relative z-10 overflow-hidden rounded bg-gray-600 py-12 lg:px-8 md:p-[70px]">
							<div className="flex flex-wrap items-center -mx-4">
								<div className="w-full px-4 m-auto text-center text-lg lg:text-3xl">
									<span className="block mb-4 font-medium text-white">New Dawn Core Apps</span>
									<h2 className="mb-6 font-normal leading-tight text-white sm:mb-8 sm:text-[40px]/[48px] lg:mb-0">
										<span className="xs:block text-2xl">Explore Our Suite of Innovative Applications: Powering Businesses with Tailored Solutions</span>
									</h2>
								</div>
							</div>
							<div>
								<span className="absolute top-0 left-0 z-[-1]">
									<svg width="189" height="162" viewBox="0 0 189 162" fill="none" xmlns="http://www.w3.org/2000/svg">
										<ellipse cx="16" cy="-16.5" rx="173" ry="178.5" transform="rotate(180 16 -16.5)" fill="url(#paint0_linear)" />
										<defs>
											<linearGradient id="paint0_linear" x1="-157" y1="-107.754" x2="98.5011" y2="-106.425" gradientUnits="userSpaceOnUse">
												<stop stopColor="white" stopOpacity="0.07" />
												<stop offset="1" stopColor="white" stopOpacity="0" />
											</linearGradient>
										</defs>
									</svg>
								</span>
								<span className="absolute bottom-0 right-0 z-[-1]">
									<svg width="191" height="208" viewBox="0 0 191 208" fill="none" xmlns="http://www.w3.org/2000/svg">
										<ellipse cx="173" cy="178.5" rx="173" ry="178.5" fill="url(#paint0_linear)" />
										<defs>
											<linearGradient id="paint0_linear" x1="-3.27832e-05" y1="87.2457" x2="255.501" y2="88.5747" gradientUnits="userSpaceOnUse">
												<stop stopColor="white" stopOpacity="0.07" />
												<stop offset="1" stopColor="white" stopOpacity="0" />
											</linearGradient>
										</defs>
									</svg>
								</span>
							</div>
						</div>
					</div>
				</section>
				<section className="bg-alpine-50 pt-5 pb-10 lg:pt-5 lg:pb-20">
					<div className="container mx-auto">
						<div className="flex flex-wrap">
							<Swiper
								slidesPerView={1}
								spaceBetween={30}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false,
									reverseDirection: false,
								}}
								breakpoints={{
									1024: {
										slidesPerView: 2,
										spaceBetween: 50,
									},
								}}
								loop={true}
								modules={[Autoplay]}
								className="testimonialSwiper"
							>
								<SwiperSlide>
									<div className="max-w-lg m-auto border border-newdawn-200 rounded-lg shadow bg-newdawn-950">
										<img className="rounded-t-lg h-[300px] w-[512px]" src={inv} alt="" />
										<div className="p-5">
											<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Invoice Management</h5>
											<p className="mb-3 font-normal text-gray-700 dark:text-gray-400 h-44 lg:h-36">Our cloud-based Invoice Management System simplifies billing and payment tracking, offering automated features, real-time reporting, and easy integration with existing accounting platforms, enhancing efficiency and financial transparency for businesses of all sizes.</p>
											<a href="/invoice-management" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-alpine-700 rounded-lg hover:bg-alpine-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
												Read more
												<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
													<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
												</svg>
											</a>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="max-w-lg m-auto border border-newdawn-200 rounded-lg shadow bg-newdawn-950 mt-10 lg:mt-0">
										<img className="rounded-t-lg h-[300px] w-[512px]" src={trad} alt="" />
										<div className="p-5">
											<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Tele Radiology</h5>
											<p className="mb-3 font-normal text-gray-700 dark:text-gray-400 h-44 lg:h-36">Our Teleradiology Platform offers a cloud-based, secure solution for remote medical imaging analysis, enabling efficient, real-time collaboration and diagnoses between healthcare professionals and radiologists.</p>
											<a href="/contact-us" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-alpine-700 rounded-lg hover:bg-alpine-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
												Read more
												<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
													<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
												</svg>
											</a>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="max-w-lg m-auto border border-newdawn-200 rounded-lg shadow bg-newdawn-950 mt-10 lg:mt-0">
										<img className="rounded-t-lg h-[300px] w-[512px]" src={order} alt="" />
										<div className="p-5">
											<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Order Capturing</h5>
											<p className="mb-3 font-normal text-gray-700 dark:text-gray-400 h-44 lg:h-36">Our cloud-based order capturing system streamlines the recording and management of customer orders, enhancing efficiency and accuracy in sectors like retail and hospitality. It combines real-time order tracking and inventory management in a user-friendly, accessible cloud environment.</p>
											<a href="/contact-us" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-alpine-700 rounded-lg hover:bg-alpine-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
												Read more
												<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
													<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
												</svg>
											</a>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="max-w-lg m-auto border border-newdawn-200 rounded-lg shadow bg-newdawn-950 mt-10 lg:mt-0">
										<img className="rounded-t-lg h-[300px] w-[512px]" src={event} alt="" />
										<div className="p-5">
											<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Events Management</h5>
											<p className="mb-3 font-normal text-gray-700 dark:text-gray-400 h-44 lg:h-36">Simplify event planning with our cloud-based solution. Streamline every aspect of event organization for seamless experiences. Join us for effortless event coordination and tracking.</p>
											<a href="/contact-us" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-alpine-700 rounded-lg hover:bg-alpine-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
												Read more
												<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
													<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
												</svg>
											</a>
										</div>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
				</section>
			</>
		);
	}
}
