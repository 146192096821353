import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

export default class Footer extends React.Component {
	render() {
		return (
			<>
				<footer className="relative bg-newdawn-950 pt-8 pb-6 text-white">
					<div className="container mx-auto px-4">
						<div className="flex flex-wrap text-left lg:text-left">
							<div className="w-full lg:w-6/12 px-4">
								<h4 className="text-3xl fonat-semibold">Let's keep in touch!</h4>
								<h5 className="text-lg mt-0 mb-2">Find us on any of these platforms, we respond 1-2 business days.</h5>
								<div className="mt-6 lg:mb-0 mb-6">
									<button className="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
										<a href="/contact-us">
											<FaTwitter className="text-newdawn-950 m-auto" />
										</a>
									</button>
									<button className="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
										<a href="/contact-us">
											<FaFacebook className="text-newdawn-950 m-auto" />
										</a>
									</button>
									<button className="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
										<a href="/contact-us">
											<FaInstagram className="text-newdawn-950 m-auto" />
										</a>
									</button>
									<button className="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
										<a href="/contact-us">
											<FaLinkedin className="text-newdawn-950 m-auto" />
										</a>
									</button>
								</div>
							</div>
							<div className="w-full lg:w-6/12 px-4 mt-10 lg:mt-0">
								<div className="flex flex-wrap items-top mb-6">
									<div className="w-full lg:w-4/12 px-4 ml-auto">
										<span className="block uppercase text-sm font-semibold mb-2">Useful Links</span>
										<ul className="list-unstyled">
											<li>
												<a className="font-normal block pb-2 text-sm" href="/about-us">
													About Us
												</a>
											</li>
											<li>
												<a className="font-normal block pb-2 text-sm" href="/invoice-management">
													Products
												</a>
											</li>
											<li>
												<a className="font-normal block pb-2 text-sm" href="/our-process">
													Our Process
												</a>
											</li>
										</ul>
									</div>
									<div className="w-full lg:w-4/12 px-4 mt-10 lg:mt-0">
										<span className="block uppercase text-sm font-semibold mb-2">Other Resources</span>
										<ul className="list-unstyled">
											<li>
												<a className="font-normal block pb-2 text-sm" href="/contact-us">
													Contact Us
												</a>
											</li>
											<li>
												<a className="font-normal block pb-2 text-sm" href="/study-ecomm">
													Ecommerce Application
												</a>
											</li>
											<li>
												<a className="font-normal block pb-2 text-sm" href="/study-intranet">
													Intranet Portal
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<hr className="my-6 border-gray-300" />
						<div className="flex flex-wrap items-center md:justify-between justify-center">
							<div className="w-full md:w-4/12 px-4 mx-auto text-center">
								<div className="text-sm font-normal py-1">
									Copyright © <span id="get-current-year">{new Date().getFullYear()}</span>&nbsp;New Dawn IT Solutions
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}
