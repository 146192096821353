import React from "react";

export default class HomeHero extends React.Component {
	render() {
		return (
			<div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 bg-newdawn-50 lg:bg-white">
				<div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
					<svg className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block" viewBox="0 0 100 100" fill="currentColor" preserveAspectRatio="none slice">
						<path d="M50 0H100L50 100H0L50 0Z"></path>
					</svg>
					<img className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full" src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" alt="" />
				</div>
				<div className="relative flex flex-col items-start w-full max-w-2xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-2xl">
					<div className="mb-16 lg:my-40 lg:max-w-xl lg:pr-5">
						<h2 className="mb-5 text-3xl font-bold tracking-normal text-gray-900 sm:text-4xl sm:leading-none">Architecting Digital Brilliance</h2>
						<h3 className="text-2xl">
							Crafting <span className="inline-block text-alpine-400">&nbsp;Tomorrow's&nbsp;</span>IT Solutions
							<span className="inline-block text-alpine-400">&nbsp;Today</span>
						</h3>
						<p className="pr-5 mb-5 text-base text-gray-500 md:text-lg mt-5">Welcome to New Dawn IT Solutions. We merge innovation with expertise to create forward-thinking IT solutions tailored for your business needs. Elevate your digital journey with our commitment to excellence and a passion for transformative technology.</p>
						<div className="flex items-center">
							<a href="/contact-us" className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-newdawn-950 hover:bg-newdawn-700 focus:shadow-outline focus:outline-none">
								Get started
							</a>
							<a href="/about-us" aria-label="" className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700">
								Learn more
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
