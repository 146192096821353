import React from "react";
import AppLayout from "../layouts/AppLayout";
import ecomm from "../assets/ecommerce.png";
import ecommchallenge from "../assets/ecomm-challenge.png";
import { FaAnchor, FaCheckSquare, FaStar, FaThumbsUp } from "react-icons/fa";

export default class StudyEcomm extends React.Component<any, any> {
	render() {
		return (
			<AppLayout>
				<section className="relative bg-cover bg-center bg-opacity-50 h-96">
					<div
						className="absolute inset-0 bg-cover bg-center"
						style={{
							backgroundImage: `url(${ecomm})`,
						}}
					></div>
					<div className="absolute inset-0 bg-black opacity-60"></div>
					<div className="absolute inset-0 flex items-center justify-center">
						<div className="container mx-auto px-6 p-6">
							<div className="mb-16 text-center">
								<p className="my-2 text-2xl lg:text-5xl font-medium tracking-tight text-white">Case Study: Development of a Dual-Application Ecommerce Platform</p>
								<h4 className="text-sm lg:text-base text-white font-normal lg:font-semibold tracking-wide uppercase">Revolutionizing Online Retail: Building a Seamless Interface for Buyers and Sellers with Angular, .NET Core API, and PostgreSQL</h4>
							</div>
						</div>
					</div>
				</section>
				<div className="py-16 bg-gray-50">
					<section className="relative">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h2 className="text-3xl font-semibold text-gray-800">Client - Archway</h2>
								<p className="mt-2 text-lg text-gray-600">Archway is a leading provider of marketing logistics, fulfillment services, and supply chain management solutions. They help brands achieve greater return on marketing execution and product distribution with tailored solutions that drive financial efficiencies, grow revenue, and increase profitability.</p>
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
								<div className="lg:col-span-2">
									<div className="max-w-4xl mx-auto">
										<section className="relative bg-white py-16 rounded-lg my-10">
											<div className="container mx-auto px-6">
												<div className="max-w-4xl mx-auto">
													<h2 className="text-3xl font-semibold text-gray-800">Challenges</h2>
													<ul className="mt-4 text-lg text-gray-600 space-y-2">
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Scalability Issues</strong>: Difficulty in managing the growing number of users and transactions on their platform.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Limited Seller and Buyer Features</strong>: Lack of advanced tools for sellers to manage their products and inadequate user experience for buyers.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Integration and Data Synchronization Problems</strong>: Struggles with integrating the seller and buyer platforms for real-time data consistency.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Security and Data Protection Concerns</strong>: Need for stronger security measures to protect user data and transactions.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Lack of Mobile Optimization</strong>: Absence of a mobile-responsive design, limiting reach to mobile users.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Inflexibility to Market Changes</strong>: The platform's inability to quickly adapt to changing market trends and user demands.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Operational Inefficiencies</strong>: High operational and maintenance costs due to outdated systems and processes.
															</div>
														</li>
													</ul>
													<p className="text-gray-800 mt-4 text-lg">Our collaboration with Archway aimed to transform their ecommerce platform, addressing these challenges head-on and paving the way for a more robust, scalable, and user-centric online retail experience.</p>
												</div>
											</div>
										</section>
									</div>
								</div>
								<div className="hidden lg:flex lg:col-span-1 items-center justify-center">
									<img src={ecommchallenge} alt="Ecommerce Challenge" className="max-w-full h-auto" />
								</div>
							</div>
						</div>
					</section>
					<section className="relative lg:pt-8">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h2 className="text-3xl font-semibold text-gray-800">Solution</h2>
								<p className="mt-2 text-lg text-gray-600">To address the challenges faced by Archway, we proposed the development of a dual-application ecommerce platform.</p>
								<h3 className="text-2xl font-semibold text-gray-800 mt-4 py-2">Seller Application</h3>
								<p className="text-lg text-gray-600">The seller application is a web-based platform that allows sellers to manage their products, orders, and inventory. It also provides them with access to real-time data and analytics to help them make better business decisions.</p>
								<div className=" bg-white py-4 rounded-lg my-5 max-w-4xl px-6">
									<h3 className="text-xl font-semibold text-gray-800 mt-4">Features</h3>
									<div className="my-auto">
										<ul className="mt-4 text-lg text-gray-600 space-y-2">
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Category Management</strong>: Admins can create, modify, and organize product categories.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Item Management</strong>: Allows for the addition, updating, and removal of items.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>User and Group Management</strong>: Admins can manage user accounts and group memberships.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Item-Category Assignment</strong>: Facilitates the assignment of items to specific categories.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Category-User Group Assignment</strong>: Enables admins to assign categories to different user groups.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Allocations Management</strong>: Involves managing resource and inventory allocations.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Homepage and Carousel Management</strong>: Admins can update the buyer site's homepage announcements and carousel displays.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Featured Products</strong>: Ability to highlight and manage featured products for promotional purposes.
												</div>
											</li>
										</ul>
									</div>
								</div>
								<h3 className="text-2xl font-semibold text-gray-800 mt-4 py-2">Buyer Application</h3>
								<p className="text-lg text-gray-600">The buyer application is a web-based platform that allows buyers to browse and purchase products. It also provides them with access to real-time data and analytics to help them make better purchasing decisions.</p>
								<div className=" bg-white py-4 rounded-lg my-5 max-w-4xl px-6">
									<h3 className="text-xl font-semibold text-gray-800 mt-4">Features</h3>
									<div className="my-auto">
										<ul className="mt-4 text-lg text-gray-600 space-y-2">
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Personalized Item Display</strong>: Users see items based on their group assignments and preferences.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Search and Browse Functionality</strong>: Includes advanced search options and category-based browsing.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Shopping Cart</strong>: Users can add items to their cart and proceed to checkout.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Order Placement</strong>: Streamlined process for placing orders.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Order Tracking</strong>: Users can track the status of their orders.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Order History</strong>: Users can view their order history.
												</div>
											</li>
											<li className="flex">
												<i className="mr-3 mt-2">
													<FaThumbsUp />
												</i>
												<div>
													<strong>Address Management</strong>: Users can add or modify their delivery addresses.
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="py-8">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h3 className="text-3xl font-semibold text-gray-800">Outcomes and Impact</h3>
								<p className="mt-2 text-lg text-gray-600">The implementation of the e-commerce platform has resulted in several positive outcomes and impacts</p>
								<div className=" bg-white py-4 rounded-lg my-5 max-w-4xl px-6">
									<ul className="mt-4 text-lg text-gray-600 space-y-2">
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Increased sales and revenue for the company.</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Improved customer satisfaction and user experience.</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Streamlined order management and fulfillment processes.</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Expanded market reach and customer base.</strong>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
					<section className="py-8">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h3 className="text-3xl font-semibold text-gray-800">Future Enhancements</h3>
								<p className="mt-2 text-lg text-gray-600">The e-commerce platform has the potential for future enhancements and improvements:</p>
								<div className=" bg-white py-4 rounded-lg my-5 max-w-4xl px-6">
									<ul className="mt-4 text-lg text-gray-600 space-y-2">
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaStar />
											</i>
											<strong>Integration with social media platforms for seamless sharing and promotion.</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaStar />
											</i>
											<strong>Personalized recommendations based on user preferences and browsing history.</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaStar />
											</i>
											<strong>Enhanced analytics and reporting capabilities for better insights into customer behavior.</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaStar />
											</i>
											<strong>Integration with third-party payment gateways for more payment options.</strong>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
					<section className="pt-8">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h3 className="text-3xl font-semibold text-gray-800">Conclusion</h3>
								<p className="mt-2 text-lg text-gray-600">In conclusion, the e-commerce platform has proven to be a valuable asset for the company, providing increased sales and revenue, improved customer satisfaction, streamlined order management, and expanded market reach. With the potential for future enhancements and improvements, such as integration with social media platforms, personalized recommendations, enhanced analytics, and integration with third-party payment gateways, the platform will continue to drive growth and success for the company.</p>
							</div>
						</div>
					</section>
				</div>
			</AppLayout>
		);
	}
}
