import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import logo from "../assets/logo.png";
import logotext from "../assets/logo_text.png";

export default class Header extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			redirect: "",
			mobileCSExpanded: false,
		};
	}

	componentDidMount(): void {
		const burger = document.querySelectorAll(".navbar-burger");
		const menu = document.querySelectorAll(".navbar-menu");
		if (burger.length && menu.length) {
			for (var i = 0; i < burger.length; i++) {
				burger[i].addEventListener("click", function () {
					for (var j = 0; j < menu.length; j++) {
						menu[j].classList.toggle("hidden");
					}
				});
			}
		}
		// close
		const close = document.querySelectorAll(".navbar-close");
		const backdrop = document.querySelectorAll(".navbar-backdrop");
		if (close.length) {
			for (var l = 0; l < close.length; l++) {
				close[l].addEventListener("click", function () {
					for (var j = 0; j < menu.length; j++) {
						menu[j].classList.toggle("hidden");
					}
				});
			}
		}

		if (backdrop.length) {
			for (var k = 0; k < backdrop.length; k++) {
				backdrop[k].addEventListener("click", function () {
					for (var j = 0; j < menu.length; j++) {
						menu[j].classList.toggle("hidden");
					}
				});
			}
		}
	}

	getActiveClass(path: string) {
		if (window.location.href.indexOf(path) > -1) {
			return "bg-alpine-500 text-white";
		}

		return "text-newdawn-950";
	}
	render() {
		return (
			<header className="h-20 text-white flex bg-white border-b-[1px] border-alpine-500 fixed z-50 w-full">
				{this.state.redirect !== "" && <Navigate to={this.state.redirect} />}
				<nav className="relative px-4 py-4 flex w-full justify-between lg:justify-normal">
					<a className="text-xl font-bold leading-none text-newdawn-950 flex" href="/home">
						<img src={logo} alt="New Dawn IT" className="my-auto ml-3 cursor-pointer h-14" />
						<img src={logotext} alt="New Dawn IT" className="ml-3 cursor-pointer h-7 mt-4" />
					</a>
					<div className="lg:hidden">
						<button className="navbar-burger flex items-center text-newdawn-950 p-3">
							<svg className="block h-7 w-7 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<title>Mobile menu</title>
								<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
							</svg>
						</button>
					</div>
					<ul className="hidden lg:flex lg:my-auto lg:ml-10 lg:space-x-3">
						<li>
							<div className={"text-base px-2 py-2 hover:bg-alpine-200 rounded-md " + this.getActiveClass("/home")}>
								<a className={""} href="/home">
									Home
								</a>
							</div>
						</li>
						<li>
							<div className={"text-base px-2 py-2 hover:bg-alpine-200 rounded-md " + this.getActiveClass("/invoice-management")}>
								<a className={""} href="/invoice-management">
									Products
								</a>
							</div>
						</li>
						<li>
							<div className={"text-base px-2 py-2 hover:bg-alpine-200 rounded-md " + this.getActiveClass("/our-process")}>
								<a className={""} href="/our-process">
									Our Process
								</a>
							</div>
						</li>
						<li>
							<div className="relative group text-base">
								<div className={"flex cursor-pointer w-36 group-hover:bg-alpine-200 rounded-md align-top px-2 py-2 " + this.getActiveClass("/study-")}>
									<span className="">Case Studies</span>
									<FaChevronDown className="mt-1 ml-1" />
								</div>
								<div className="hidden relative group-hover:block z-50 bg-white">
									<ul className="absolute mt-0 flex flex-col justify-center bg-white rounded-md  border border-alpine-200 w-60 text-newdawn-950">
										<li className="w-60 hover:bg-alpine-200 p-3 border-b border-alpine-200 cursor-pointer">
											<a href="/study-ecomm">
												<span className="w-60">An Ecommerce Portal</span>
											</a>
										</li>
										<li className="w-60 hover:bg-alpine-200 p-3 cursor-pointer">
											<a href="/study-intranet">
												<span className="w-60">Intranet</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li>
							<div className={"text-base px-2 py-2 hover:bg-alpine-200 rounded-md " + this.getActiveClass("/contact-us")}>
								<a className={""} href="/contact-us">
									Contact Us
								</a>
							</div>
						</li>
						<li>
							<div className={"text-base px-2 py-2 hover:bg-alpine-200 rounded-md " + this.getActiveClass("/about-us")}>
								<a className={""} href="/about-us">
									About Us
								</a>
							</div>
						</li>
						<li>
							<div className={"text-base px-2 py-2 hover:bg-alpine-200 rounded-md " + this.getActiveClass("https://devstackup.in")}>
								<a className={""} href="https://devstackup.in" rel="noreferrer" target={"_blank"}>
									Training
								</a>
							</div>
						</li>
					</ul>
				</nav>
				<div className="navbar-menu relative z-50 hidden">
					<div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
					<nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white text-newdawn-950 border-r overflow-y-auto">
						<div className="flex items-center mb-8">
							<a className="mr-auto text-xl font-bold leading-none" href="/home">
								<img src={logotext} alt="New Dawn IT Solutions" className="my-auto ml-3 cursor-pointer h-7" />
							</a>
							<button className="navbar-close">
								<svg className="h-6 w-6 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
								</svg>
							</button>
						</div>
						<div>
							<ul>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/home">
										Home
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/about-us">
										About Us
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/our-process">
										Our Process
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/invoice-management">
										Products
									</a>
								</li>
								<li className="mb-1">
									<div
										className="flex p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded cursor-pointer"
										onClick={() => {
											this.setState({
												mobileCSExpanded: !this.state.mobileCSExpanded,
											});
										}}
									>
										Case Studies
										{!this.state.mobileCSExpanded && <FaChevronDown className="mt-1 ml-1" />}
										{this.state.mobileCSExpanded && <FaChevronUp className="mt-1 ml-1" />}
									</div>
								</li>
								{this.state.mobileCSExpanded && (
									<li className="mb-1 ml-2">
										<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/study-ecomm">
											An Ecommerce Portal
										</a>
									</li>
								)}
								{this.state.mobileCSExpanded && (
									<li className="mb-1 ml-2">
										<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/study-intranet">
											Intranet
										</a>
									</li>
								)}
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="/contact-us">
										Contact Us
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold hover:bg-blue-50 hover:text-blue-600 rounded" href="https://devstackup.in">
										Training
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</header>
		);
	}
}
