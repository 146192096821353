import React from "react";
import AppLayout from "../layouts/AppLayout";
import intranet from "../assets/intranet.jpg";
import intranetheader from "../assets/intranet-header.jpg";
import { FaAnchor, FaCheckSquare, FaThumbsUp } from "react-icons/fa";

export default class StudyIntranet extends React.Component<any, any> {
	render() {
		return (
			<AppLayout>
				<section className="relative bg-cover bg-center bg-opacity-50 h-96">
					<div
						className="absolute inset-0 bg-cover bg-center"
						style={{
							backgroundImage: `url(${intranetheader})`,
						}}
					></div>
					<div className="absolute inset-0 bg-black opacity-60"></div>
					<div className="absolute inset-0 flex items-center justify-center">
						<div className="container mx-auto px-6 p-6">
							<div className="mb-16 text-center">
								<p className="my-2 text-2xl lg:text-5xl font-medium tracking-tight text-white">Case Study: Custom SharePoint Online Intranet Solution</p>
								<h4 className="text-sm lg:text-base text-white font-normal lg:font-semibold tracking-wide uppercase">Enhancing Business Collaboration and Productivity with a Tailored SharePoint Intranet</h4>
							</div>
						</div>
					</div>
				</section>
				<div className="py-16 bg-gray-50">
					<section className="relative">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<p className="mt-2 text-2xl text-gray-600">Our client, a leading provider of technology solutions, was looking to enhance their existing intranet solution. They wanted to improve the user experience, streamline data integration, and provide customization options for individual users. They also wanted to develop a comprehensive people directory with advanced search capabilities. Our team successfully developed and deployed a comprehensive intranet solution on SharePoint Online, tailored to meet the specific needs of our client. This case study details the features and functionalities we integrated, focusing on user experience, data integration, and customization.</p>
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
								<div className="lg:col-span-2">
									<div className="max-w-4xl mx-auto">
										<section className="relative bg-white py-16 rounded-lg my-10">
											<div className="container mx-auto px-6">
												<div className="max-w-4xl mx-auto">
													<h2 className="text-3xl font-semibold text-gray-800">Objectives</h2>
													<ul className="mt-4 text-lg text-gray-600 space-y-2">
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Enhanced Navigation</strong>: Implement a user-friendly navigation system.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Integrated Search Functionality</strong>: Create a robust search feature that includes internal and external data sources.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Personalized User Experience</strong>: Provide customization options for individual users.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Data Visualization and Accessibility</strong>: Display relevant data in an easily digestible format.
															</div>
														</li>
														<li className="flex">
															<i className="mr-3 mt-2">
																<FaAnchor />
															</i>
															<div>
																<strong>Comprehensive People Directory</strong>: Develop an extensive directory with advanced search capabilities.
															</div>
														</li>
													</ul>
												</div>
											</div>
										</section>
										<section className="relative lg:pt-8">
											<div className="container mx-auto px-6">
												<div className="max-w-4xl mx-auto">
													<h2 className="text-3xl font-semibold text-gray-800">Solution</h2>
													<p className="mt-2 text-lg text-gray-600">To address the challenges faced by our client, we developed a comprehensive intranet solution on SharePoint Online. The solution included the following features and functionalities</p>
													<div className=" bg-white py-4 rounded-lg my-5 max-w-4xl px-6">
														<h3 className="text-xl font-semibold text-gray-800 mt-4">Features</h3>
														<div className="my-auto">
															<ul className="mt-4 text-lg text-gray-600 space-y-2">
																<li className="flex">
																	<i className="mr-3 mt-2">
																		<FaThumbsUp />
																	</i>
																	<div>
																		<strong>Custom Navigation</strong>: We created a custom navigation system that included a mega menu with drop-downs, quick links, and a search bar. The mega menu was organized into three main categories: <strong>Departments</strong>, <strong>Offices</strong>, and <strong>Resources</strong>.
																	</div>
																</li>
																<li className="flex">
																	<i className="mr-3 mt-2">
																		<FaThumbsUp />
																	</i>
																	<div>
																		<strong>User-Friendly Design</strong>: The navigation system was designed to be user-friendly and intuitive, with a clean and modern look and feel.
																	</div>
																</li>
																<li className="flex">
																	<i className="mr-3 mt-2">
																		<FaThumbsUp />
																	</i>
																	<div>
																		<strong>Integrated Search Functionality</strong>: Created a robust search feature that includes internal and external data sources.
																	</div>
																</li>
																<li className="flex">
																	<i className="mr-3 mt-2">
																		<FaThumbsUp />
																	</i>
																	<div>
																		<strong>Personalized User Experience</strong>: Provided customization options for individual users.
																	</div>
																</li>
																<li className="flex">
																	<i className="mr-3 mt-2">
																		<FaThumbsUp />
																	</i>
																	<div>
																		<strong>Data Visualization and Accessibility</strong>: Displayed relevant data in an easily digestible format.
																	</div>
																</li>
																<li className="flex">
																	<i className="mr-3 mt-2">
																		<FaThumbsUp />
																	</i>
																	<div>
																		<strong>Comprehensive People Directory</strong>: Developed an extensive directory with advanced search capabilities.
																	</div>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<div className="hidden lg:flex lg:col-span-1 items-center justify-center">
									<img src={intranet} alt="Ecommerce Challenge" className="max-w-full h-auto mb-auto mt-20" />
								</div>
							</div>
						</div>
					</section>

					<section className="py-8">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h3 className="text-3xl font-semibold text-gray-800">Outcomes and Impact</h3>
								<div className=" bg-white py-4 rounded-lg my-5 max-w-4xl px-6">
									<ul className="mt-4 text-lg text-gray-600 space-y-2">
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Improved Navigation Experience</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Personalized User Interface</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Robust Search Functionality</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Centralized Data Hub</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Improved Collaboration and Productivity</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Notification System</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Enhanced Data Visualization</strong>
										</li>
										<li className="flex">
											<i className="mr-3 mt-2">
												<FaCheckSquare />
											</i>
											<strong>Dynamic People Directory</strong>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
					<section className="pt-8">
						<div className="container mx-auto px-6">
							<div className="my-auto lg:px-10">
								<h3 className="text-3xl font-semibold text-gray-800">Conclusion</h3>
								<p className="mt-2 text-lg text-gray-600">The deployment of this custom SharePoint Online intranet solution has significantly impacted the way the organization operates, driving improvements in productivity, collaboration, and decision-making. By providing a user-friendly, information-rich, and highly interactive digital workspace, the solution has not only enhanced day-to-day operations but also set a foundation for continuous growth and innovation in the organization's digital strategy.</p>
							</div>
						</div>
					</section>
				</div>
			</AppLayout>
		);
	}
}
